<template>
  <div class="reset-pass reset">
    <div class="mt-2 mb-5 text-center">
      <h1 class="forget-title">{{ $t("g.resetPassword") }}</h1>
      <p class="forget-desc">{{ $t("g.pleaseEnterYourNewPassword") }}</p>
    </div>

    <validation-observer ref="resetPasswordValidation" class="d-flex justify-content-center align-items-center">
      <b-form class="forget-password-form" @submit.prevent>
        <b-form-group label-for="reset-password-password">
          <validation-provider #default="{ errors }" name="password"
            rules="required|min:8|max:20|confirmed:password_confirmation">
            <b-form-input id="reset-password-password" v-model="form_data.password"
              :state="errors.length > 0 ? false : null" name="reset-password-password" :placeholder="$t('g.password')"
              type="password" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="reset-password-password-confirmation">
          <validation-provider #default="{ errors }" name="password_confirmation" rules="required|min:8|max:20">
            <b-form-input id="reset-password-password-confirmation" v-model="form_data.password_confirmation"
              :state="errors.length > 0 ? false : null" name="reset-password-password-confirmation"
              :placeholder="$t('g.confirmPassword')" type="password" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button type="submit" variant="primary" block @click="resetPasswordValidationForm" class="primary-btn"
          :disabled="isPending">
          <div v-if="isPending" class="loading_" data-loading="light" />
          <span v-else>{{ $t("g.resetPassword") }}</span>
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import toast from '@/libs/toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { max, min, required, confirmed } from '@core/utils/validations/validations'

export default {
  name: 'ResetPasword',
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    min,
    max,
    confirmed,
    BFormInput,
    BButton,
    BForm,
    BFormGroup
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    pin_code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form_data: {
        password: '',
        password_confirmation: '',
      },
      isPending: false,
    }
  },
  methods: {
    resetPasswordValidationForm() {
      this.$refs.resetPasswordValidation.validate()
        .then((success) => {
          if (success) {
            this.isPending = true
            const formData = new FormData()
            formData.append('email', this.email)
            formData.append('pin_code', this.pin_code)
            formData.append('password', this.form_data.password)
            formData.append('password_confirmation', this.form_data.password_confirmation)
            this.$http
              .post('auth/admin/reset/reset_password', formData)
              .then((res) => {
                if (res.status === 200) {
                  this.$router.push({ name: 'Login' })
                  toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome`,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      text: this.$t('g.passwordResetSuccessfully'),
                    },
                  })
                }
              })
              .catch((err) => {
                console.log(err)
                toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Error`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: this.$t('g.passwordResetError'),
                  },
                })
              })
              .finally(() => {
                this.isPending = false
              })
          }
        })
    },
  },
}
</script>

<style lang="scss" src="./_resetPassword.scss" />
